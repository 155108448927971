import { graphql, useStaticQuery } from "gatsby"

export default function useSiteMetadata() {
  const data = useStaticQuery(graphql`
    query getMetadata {
      site {
        siteMetadata {
          title
          description
          repoUrl
          footerData {
            footer_text
          }
          frontPageData {
            desktop_announcement
            desktop_logo_line
            mobile_title
            mobile_announcement
            mobile_logo_line
          }
          currentEventData {
            event_title
            event_location
            event_date
            event_description
            event_link
            tickets_link
          }
          biographyData {
            hidden
            quote
            quotee
            biography
          }
        }
      }
    }
  `)
  return data.site.siteMetadata
}
