import React from "react"
import MetaData from "./MetaData"
//import * as layoutStyles from '../styles/components/layout.module.scss'
import "@fontsource/marvel"
import "@fontsource/montserrat"

import "../styles/global.scss"
import "../styles/overrides.scss"

export default function Layout(props) {
  return (
    <section>
      <MetaData />
      <div>{props.children}</div>
    </section>
  )
}
