import React from "react"
import Helmet from "react-helmet"
import useSiteMetaData from "../static_queries/useSiteMetadata"

export default function MetaData() {
  const { title, description } = useSiteMetaData()
  return (
    <Helmet defer={false}>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Colin Crowther" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta name="twitter:card" content={description} />
      <meta name="twitter:site" content="@heatherband" />
      <meta name="twitter:title" content="Heather" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@cmacrowther" />
      <meta name="twitter:image" content="https://www.heatherband.ca/og.png" />
      <meta property="og:title" content="Heather" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.heatherband.ca" />
      <meta property="og:image" content="https://www.heatherband.ca/og.png" />
      <meta
        property="og:image:secure_url"
        content="https://www.heatherband.ca/og.png"
      />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Heather" />
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
      <meta name="theme-color" content="#1c1a14" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#000000" />
    </Helmet>
  )
}
